
/*! NOTE: If you're already including a window.matchMedia polyfill via Modernizr or otherwise, you don't need this part */
"use strict";

window.matchMedia = window.matchMedia || (function (a) {
  "use strict";var c,
      d = a.documentElement,
      e = d.firstElementChild || d.firstChild,
      f = a.createElement("body"),
      g = a.createElement("div");return g.id = "mq-test-1", g.style.cssText = "position:absolute;top:-100em", f.style.background = "none", f.appendChild(g), function (a) {
    return g.innerHTML = '&shy;<style media="' + a + '"> #mq-test-1 { width: 42px; }</style>', d.insertBefore(f, e), c = 42 === g.offsetWidth, d.removeChild(f), { matches: c, media: a };
  };
})(document);

/*! Respond.js v1.1.0: min/max-width media query polyfill. (c) Scott Jehl. MIT/GPLv2 Lic. j.mp/respondjs  */
(function (a) {
  "use strict";function x() {
    u(!0);
  }var b = {};if ((a.respond = b, b.update = function () {}, b.mediaQueriesSupported = a.matchMedia && a.matchMedia("only all").matches, !b.mediaQueriesSupported)) {
    var q,
        r,
        t,
        c = a.document,
        d = c.documentElement,
        e = [],
        f = [],
        g = [],
        h = {},
        i = 30,
        j = c.getElementsByTagName("head")[0] || d,
        k = c.getElementsByTagName("base")[0],
        l = j.getElementsByTagName("link"),
        m = [],
        n = function n() {
      for (var b = 0; l.length > b; b++) {
        var c = l[b],
            d = c.href,
            e = c.media,
            f = c.rel && "stylesheet" === c.rel.toLowerCase();d && f && !h[d] && (c.styleSheet && c.styleSheet.rawCssText ? (p(c.styleSheet.rawCssText, d, e), h[d] = !0) : (!/^([a-zA-Z:]*\/\/)/.test(d) && !k || d.replace(RegExp.$1, "").split("/")[0] === a.location.host) && m.push({ href: d, media: e }));
      }o();
    },
        o = function o() {
      if (m.length) {
        var b = m.shift();v(b.href, function (c) {
          p(c, b.href, b.media), h[b.href] = !0, a.setTimeout(function () {
            o();
          }, 0);
        });
      }
    },
        p = function p(a, b, c) {
      var d = a.match(/@media[^\{]+\{([^\{\}]*\{[^\}\{]*\})+/gi),
          g = d && d.length || 0;b = b.substring(0, b.lastIndexOf("/"));var h = function h(a) {
        return a.replace(/(url\()['"]?([^\/\)'"][^:\)'"]+)['"]?(\))/g, "$1" + b + "$2$3");
      },
          i = !g && c;b.length && (b += "/"), i && (g = 1);for (var j = 0; g > j; j++) {
        var k, l, m, n;i ? (k = c, f.push(h(a))) : (k = d[j].match(/@media *([^\{]+)\{([\S\s]+?)$/) && RegExp.$1, f.push(RegExp.$2 && h(RegExp.$2))), m = k.split(","), n = m.length;for (var o = 0; n > o; o++) l = m[o], e.push({ media: l.split("(")[0].match(/(only\s+)?([a-zA-Z]+)\s?/) && RegExp.$2 || "all", rules: f.length - 1, hasquery: l.indexOf("(") > -1, minw: l.match(/\(\s*min\-width\s*:\s*(\s*[0-9\.]+)(px|em)\s*\)/) && parseFloat(RegExp.$1) + (RegExp.$2 || ""), maxw: l.match(/\(\s*max\-width\s*:\s*(\s*[0-9\.]+)(px|em)\s*\)/) && parseFloat(RegExp.$1) + (RegExp.$2 || "") });
      }u();
    },
        s = function s() {
      var a,
          b = c.createElement("div"),
          e = c.body,
          f = !1;return b.style.cssText = "position:absolute;font-size:1em;width:1em", e || (e = f = c.createElement("body"), e.style.background = "none"), e.appendChild(b), d.insertBefore(e, d.firstChild), a = b.offsetWidth, f ? d.removeChild(e) : e.removeChild(b), a = t = parseFloat(a);
    },
        u = function u(b) {
      var h = "clientWidth",
          k = d[h],
          m = "CSS1Compat" === c.compatMode && k || c.body[h] || k,
          n = {},
          o = l[l.length - 1],
          p = new Date().getTime();if (b && q && i > p - q) return a.clearTimeout(r), r = a.setTimeout(u, i), void 0;q = p;for (var v in e) if (e.hasOwnProperty(v)) {
        var w = e[v],
            x = w.minw,
            y = w.maxw,
            z = null === x,
            A = null === y,
            B = "em";x && (x = parseFloat(x) * (x.indexOf(B) > -1 ? t || s() : 1)), y && (y = parseFloat(y) * (y.indexOf(B) > -1 ? t || s() : 1)), w.hasquery && (z && A || !(z || m >= x) || !(A || y >= m)) || (n[w.media] || (n[w.media] = []), n[w.media].push(f[w.rules]));
      }for (var C in g) g.hasOwnProperty(C) && g[C] && g[C].parentNode === j && j.removeChild(g[C]);for (var D in n) if (n.hasOwnProperty(D)) {
        var E = c.createElement("style"),
            F = n[D].join("\n");E.type = "text/css", E.media = D, j.insertBefore(E, o.nextSibling), E.styleSheet ? E.styleSheet.cssText = F : E.appendChild(c.createTextNode(F)), g.push(E);
      }
    },
        v = function v(a, b) {
      var c = w();c && (c.open("GET", a, !0), c.onreadystatechange = function () {
        4 !== c.readyState || 200 !== c.status && 304 !== c.status || b(c.responseText);
      }, 4 !== c.readyState && c.send(null));
    },
        w = (function () {
      var b = !1;try {
        b = new a.XMLHttpRequest();
      } catch (c) {
        b = new a.ActiveXObject("Microsoft.XMLHTTP");
      }return function () {
        return b;
      };
    })();n(), b.update = n, a.addEventListener ? a.addEventListener("resize", x, !1) : a.attachEvent && a.attachEvent("onresize", x);
  }
})(undefined);